import {useState, useEffect} from 'react';
import './styles.css'
import moment from 'moment'
import morning from './Assets/morning.gif'
import afternoon from './Assets/afternoon.gif'
import evening from './Assets/evening.gif'
import night from './Assets/night.gif'
function App() {

  const [greeting, setgreeting] = useState({
    greet: "Hello",
    image: morning
  })
  const [location, setlocation] = useState({
    city: "",
    state: "",
    country: ""
  })
  const [weatherInfo, setweatherInfo] = useState({
    temp: "",
    icon: ""
  })
  const [currentTime, setcurrentTime] = useState("00:00:00")

  const [quote, setquote] = useState("...");

const [currentHour, setcurrentHour] = useState(0)

  function tick() {
    setcurrentTime(moment().format("HH:mm"))
    setcurrentHour(moment().format("HH"))
  }
  setInterval(tick, 1000)

  useEffect(() => {

    // !CHECK THE CURRENT HOUR IF STATEMENTS
  
      if (currentHour >= 3 && currentHour <=11) {
        setgreeting({greet: "Good Morning", image: morning})
      } else if (currentHour >= 12 && currentHour <=15) {
        setgreeting({greet: "Good Afternoon", image: afternoon})
      } else if (currentHour >= 16 && currentHour <=19) {
        setgreeting({greet: "Good Evening", image: evening})
      } else if (currentHour >= 20 || currentHour <=3) {
        setgreeting({greet: "Nigthy Night", image: night})
      } else {
        setgreeting({greet: "Hello", image: morning})
      }
  }, [currentHour])

  useEffect(() => {
    getLocation()
  }, [])

  useEffect(() => {
    getQuote()
  }, [])

  const getLocation = () => {
    var city = "";
    fetch("https://geolocation-db.com/json/", {
      method: "GET"
    }).then((res) => res.json()).then((data) => {
      setlocation({
        city: data.city,
        state: data.state,
        country: data.country_name
      })

       fetch(`https://api.openweathermap.org/data/2.5/weather?q=${data.city}&appid=f216bef769b3e7869b24fbb0b714b91f&&units=metric`, {
        method: "POST"
      }).then((res) => res.json()).then((data) => {
        // console.log(data)
        setweatherInfo({
          temp: data.main.temp,
          icon: data.weather[0].icon
        })
      })
    })

    
      
  }
  const getQuote = () => {
    fetch("https://goquotes-api.herokuapp.com/api/v1/all/quotes", {
      method: "GET"
    }).then((res) => res.json()).then((data) => {
      const min = Math.ceil(1);
      const max = Math.floor(data.count);
      const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      setquote(data.quotes[randomNumber].text)
    })

  }
  return (
    <div style={{background: `url(${greeting.image})`}} className="container">
      <div className="overlay">
        
      </div>

      <div className="weatherInfo">
        <span><img src={`http://openweathermap.org/img/w/${weatherInfo.icon}.png`} alt="icon" /> {Math.ceil(weatherInfo.temp)} &#176;</span>
        <span>{location.city + ", " + location.state}</span>
      </div>


      
      <div className="main">
        <h1>{currentTime}</h1>
        <h3>{greeting.greet}, Udoka</h3>

        <p>What is your main focus today?</p>
        <input placeholder="Focus???" type="text" />
      </div>

      <p className="quote">
        "{quote}""
      </p>
    </div>
  );
}

export default App;
